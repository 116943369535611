import axios from "@/utils/request";
import path from "./path"

const api = {
    postToken(params){
        return axios.post(path.baseUrl + path.token,params)
    },
    //首页接口
    getIndex(){
        //console.log(localStorage.getItem("token"))
        return axios.get(path.baseUrl + path.index)
    },
    //列表接口
    getLists(params){
        console.log(params);
        return axios.get(path.baseUrl + path.lists,{params})
    },
    //详情接口
    getInfo(params){
        return axios.get(path.baseUrl + path.info,{params})
    },
    dingCai(params){
        return axios.get(path.baseUrl + path.dingcai,{params})
    },
    getSpaceInfo(params){
        return axios.get(path.baseUrl + path.spaceinfo,{params})
    },
    getSpaceList(params){
        return axios.get(path.baseUrl + path.spacelist,{params})
    },
    messageSave(params){
        return axios.post(path.baseUrl + path.messageSave,{info:params.info,__token__:params.__token__})
    }
}

export default api