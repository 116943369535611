<template>
      <div class="container top-desc text-center bg-light position-fixed" style="  left: 50%;
transform: translate(-50%, 0);">
<div class="row">
    <div class="col text-start">
        <svg xmlns="http://www.w3.org/2000/svg" @click="prev" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
        </svg>
    </div>
    <div class="col text-end">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
    </svg>
    </div>
</div>
</div>
<div class="top-bank"></div>

    <div class="container">
        <div class="input-group mb-3 mt-2">
        <input type="text" class="form-control" v-model="keyword" placeholder="输入搜索内容">
        <button class="btn btn-outline-secondary" type="button" @click="search" id="button-addon2">搜索</button>
        </div>
        <hr>
    </div>
    
    <div class="container">
      <button type="button" class="btn btn-sm btn-outline-secondary m-1" @click="tosearch({tp:item.tp,keyword:item.keyword})" v-for="(item,index) in logs" :key="index">{{ item.keyword }}</button>
    </div>
</template>

<script>
export default {
  data(){
    return{
      tp:0,
      keyword:'',
      logs:[]
    }
  },mounted(){
      this.tp = this.$route.params.tp
      this.id = this.$route.params.id
      let loghave = localStorage.getItem('logs');
      if(loghave != null){
        this.logs = JSON.parse(loghave);  
      }          
    },methods:{
      prev(){
        this.$router.go(-1)
      },
      search(){
        if(this.keyword.length<1){
            alert('搜索内容不能为空')
        }else{
          let content = {
                  tp:this.tp,
                  keyword:this.keyword
              }
              this.logs.push(content);
              localStorage.setItem('logs',JSON.stringify(this.logs));
              this.tosearch({tp:this.tp,keyword:this.keyword});
        }
      },tosearch(params){
        params.id = 0;
        this.$router.push({name:'lists',params:params})
      }
    }
}
</script>