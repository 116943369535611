<template>
  <div
    class="container top-desc text-center bg-light position-fixed"
    style="left: 50%; transform: translate(-50%, 0)"
  >
    <div class="row">
      <div class="col text-start">
        <router-link :to="{ name: 'home' }">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </router-link>
      </div>
      <div class="col text-end">
        <router-link :to="{ name: 'search', params: { tp: tp } }">
          <a style="padding-right: 24px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
              />
            </svg>
          </a>
        </router-link>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-three-dots"
          viewBox="0 0 16 16"
        >
          <path
            d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
          />
        </svg>
      </div>
    </div>
  </div>
  <div class="top-bank"></div>

  <ul class="nav nav-tabs container">
    <li class="nav-item" v-if="porder == 0">
      <a
        class="nav-link active"
        aria-current="page"
        :data-porder="1"
        @click="refresh($event)"
      >
        时间
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-down-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
          />
        </svg>
      </a>
    </li>
    <li class="nav-item" v-else-if="porder == 1">
      <a
        class="nav-link active"
        aria-current="page"
        :data-porder="0"
        @click="refresh($event)"
      >
        时间
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-up-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
          />
        </svg>
      </a>
    </li>
    <li class="nav-item" v-else>
      <a class="nav-link" aria-current="page" :data-porder="0" @click="refresh($event)">
        时间
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-up-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
          />
        </svg>
      </a>
    </li>

    <li class="nav-item" v-if="porder == 2">
      <a
        class="nav-link active"
        aria-current="page"
        :data-porder="3"
        @click="refresh($event)"
      >
        人气
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-down-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
          />
        </svg>
      </a>
    </li>
    <li class="nav-item" v-else-if="porder == 3">
      <a
        class="nav-link active"
        aria-current="page"
        :data-porder="2"
        @click="refresh($event)"
      >
        人气
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-up-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
          />
        </svg>
      </a>
    </li>
    <li class="nav-item" v-else>
      <a class="nav-link" aria-current="page" :data-porder="2" @click="refresh($event)">
        人气
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-up-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
          />
        </svg>
      </a>
    </li>

    <li class="nav-item" v-if="porder == 4">
      <a
        class="nav-link active"
        aria-current="page"
        :data-porder="5"
        @click="refresh($event)"
      >
        评论
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-down-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
          />
        </svg>
      </a>
    </li>
    <li class="nav-item" v-else-if="porder == 5">
      <a
        class="nav-link active"
        aria-current="page"
        :data-porder="4"
        @click="refresh($event)"
      >
        评论
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-up-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
          />
        </svg>
      </a>
    </li>
    <li class="nav-item" v-else>
      <a class="nav-link" aria-current="page" :data-porder="4" @click="refresh($event)">
        评论
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-up-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
          />
        </svg>
      </a>
    </li>

    <li class="nav-item" v-if="porder == 6">
      <a
        class="nav-link active"
        aria-current="page"
        :data-porder="7"
        @click="refresh($event)"
      >
        赞
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-down-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
          />
        </svg>
      </a>
    </li>
    <li class="nav-item" v-else-if="porder == 7">
      <a
        class="nav-link active"
        aria-current="page"
        :data-porder="6"
        @click="refresh($event)"
      >
        赞
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-up-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
          />
        </svg>
      </a>
    </li>
    <li class="nav-item" v-else>
      <a class="nav-link" aria-current="page" :data-porder="6" @click="refresh($event)">
        赞
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-up-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
          />
        </svg>
      </a>
    </li>

    <li class="nav-item" v-if="porder == 8">
      <a
        class="nav-link active"
        aria-current="page"
        :data-porder="9"
        @click="refresh($event)"
      >
        踩
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-down-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
          />
        </svg>
      </a>
    </li>
    <li class="nav-item" v-else-if="porder == 9">
      <a
        class="nav-link active"
        aria-current="page"
        :data-porder="8"
        @click="refresh($event)"
      >
        踩
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-up-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
          />
        </svg>
      </a>
    </li>
    <li class="nav-item" v-else>
      <a class="nav-link" aria-current="page" :data-porder="8" @click="refresh($event)">
        踩
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-up-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
          />
        </svg>
      </a>
    </li>
  </ul>

  <div id="d-scroll" @scroll="listenScroll">
    <div v-for="(date_value, date_key) in lists" :key="date_key" class="container">
      <div class="time-show pt-3 text-black-50 text-center">
        {{ date_key }}
      </div>

      <div class="list_items_out bg-white rounded border my-3">
        <div v-for="(item_value, item_index) in date_value" :key="item_value.id">
          <router-link :to="{ name: 'info', params: { tp: tp, id: item_value.id } }">
            <div
              class="list_items_img rounded-bottom-1 position-relative"
              v-if="item_index == 0"
            >
              <img :src="item_value.icon" />
              <div
                class="position-absolute opacity-75 list-item rounded p-1 bg-light mx-2 text-wrap fs-5"
                style="left: 0px; bottom: 8px;"
              >
              {{ item_value.name }}
               
              </div>
            </div>

            <div class="index-list-item clearfix p-2" v-else>
              <div class="index-list-item-img rounded float-end">
                <img class="rounded" :src="item_value.icon" />
              </div>
              <div class="border-bottom fs-5" style="min-height: 75px;">
                {{ item_value.name }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <div style="height: 50px"></div>
  <!--
<div class="fixed-bottom container bg-light" >
  <div class="input-group py-2">
  <input type="text" class="form-control" placeholder="输入你的想法" aria-label="Recipient's username" aria-describedby="basic-addon2">
  <span class="input-group-text" id="basic-addon2">发送</span>
</div>
</div> -->
</template>

<script>
import api from "../api/index.js";

export default {
  data() {
    return {
      lists: {},
      tp: 0,
      porder: 0,
      current_page: 1,
      last_page: 1,
      keyword:'',
    };
  },
  mounted() {
    this.tp = this.$route.params.tp;
    this.keyword = this.$route.params.keyword;
    this.dataload(0);
  },
  methods: {
    prev() {
      this.$router.go(-1);
    },
    listenScroll(e) {
      //监听滚动
      let ele = e.srcElement ? e.srcElement : e.target;
      if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 20) {
        //监听滚动到div底部
        if (this.current_page < this.last_page) {
          this.current_page += 1;
          this.dataload();
        }
      }
    },
    refresh(e) {
      this.porder = e.target.dataset.porder != "" ? e.target.dataset.porder : 0;
      console.log(this.porder);
      this.current_page = 1;
      this.dataload();
    },
    dataload() {
      const params = {
        tp: this.$route.params.tp,
        id: this.$route.params.id,
        porder: this.porder,
        page: this.current_page,
        keyword:this.keyword
      };
      api.getLists(params).then((res) => {
        let data = res.data.data;
        if (res.data.code == 200) {
          if (data.current_page == 1) {
            this.lists = data.data;
          } else {
            let obj1 = this.lists;
            let obj2 = data.data;
            let obj = { ...obj1, ...obj2 };
            this.lists = obj;
          }
          this.current_page = Number(data.current_page);
          this.last_page = Number(data.last_page);
          document.title = data.title;
        }
      });
    },
  },
};
</script>

<style scoped>
#app {
  background: #f1f1f1;
}
#d-scroll {
  height: calc(100vh);
  overflow: auto;
}
.nav-link {
  padding: 6px 10px;
}
</style>
