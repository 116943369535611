import '@/assets/main.css'
import App from '@/App.vue'
import { createApp } from 'vue'
import router from '@/router'

import api from "@/api/index"
const token = localStorage.getItem("token");
if(token===null || token.length<10){
    api.postToken({appid:"96069054aa72172f34e15d3cc37e0fe7"}).then(res=>{
        console.log(res.data);
        if(res.data.code == 200){
            localStorage.setItem("token",res.data.data)    
            location.reload()   
        }
    })
}else{
    createApp(App).use(router).mount('#app')
}