const  base = {
  baseUrl:"https://www.outobe.com/mobile.php",
  //baseUrl:"http://www.sysaas.com/mobile.php",
  token:"//access_token.html",
  index:"/index.html",
  lists:"/lists.html",
  info:"/info.html",
  dingcai:'/dingcai.html',
  spaceinfo:'/space_info.html',
  spacelist:'/space_list.html',
  messageSave:'/message_save.html'
}

export default base;