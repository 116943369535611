<template>
  <div class="container top-desc text-center bg-light position-fixed" style="color:#ccc;  left: 50%;
transform: translate(-50%, 0);">
九九一 老头

<!--
<div class="text-end position-absolute" style=" top:0; right:12px">
<a style="padding-right: 24px;">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
</a>
<a>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
</svg>
</a>
</div>-->
</div>
<div class="top-bank"></div>

<div class="container pt-2">
  
  <div class="index-list-item  border-bottom clearfix py-2" v-for="item in lanum" :key="item.id">
    <router-link  :to="{ name: 'lists',params:{tp:item.tp,id:item.id} }">
    <div class="index-list-item-img rounded float-start"><img class="rounded" :src="item.icon"  ></div>
    <div>
        <div class="row">
          <div class="fs-5 col-7">{{item.lanmu}}</div>
          <div class="col text-end fw-lighter time-show">{{item.update_time}}</div>
        </div>     
        <div class="row">
           <div class="fw-light  col">{{item.name}}</div>
        </div> 
    </div>
  </router-link>
  </div>
  
</div>


<div style="height: 50px;"></div>
<div class="fixed-bottom container bg-light" >
<div class="input-group py-2">
<input type="text" class="form-control" placeholder="输入你的想法" v-model="message">
<span class="input-group-text" id="basic-addon2" @click="message_save">留言</span>
<span class="input-group-text btn-success" id="basic-addon2" @click="tiwen">提问</span>
</div>
</div>

</template>

<script>
import api from "../api/index"

export default {
    name:"lists",
    data(){
      return{
        lanum:{},
        token:'',
        message:''
      }
    },
    mounted(){
      api.getIndex().then(res=>{
        if(res.data.code == 200){
          this.lanum = res.data.data
          this.token = res.data.msg
          //console.log(this.token)
        }        
      })
    },methods:{
        message_save(){
          let params = {
            __token__:this.token,
            info:this.message
          }
          api.messageSave(params).then(res=>{
            if(res.data.code == 200){
              alert('发送成功，感谢你的留言')
              location.reload();
            }        
          })
        },
        tiwen(){
          if(this.message.length<3){
            alert('问题内容不能少于3个字')
          }else{
            let url = 'https://www.outobe.com/ask.html?ask=' + this.message
            window.location.href=url
          }
        }
    }
}
</script>