import { createRouter,createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
    {
        path:'/',
        name:'home',
        component:HomeView
    },{
        path:'/lists/:tp/:id?/:porder?/:keyword?',
        name:'lists',
        component:()=>import('../views/ListsView.vue')
    },{
        path:'/info/:tp/:id',
        name:'info',
        component:()=>import('../views/InfoView.vue')
    },{
        path:'/space/:mch_id?',
        name:'space',
        component:()=>import('../views/SpaceView.vue'),
    }, {
        path:'/search/:tp',
        name:'search',
        component:()=>import('../views/SearchView.vue')
    }
]

const router = createRouter({
    history:createWebHashHistory(),
    routes
})
export default router