import axios from "axios";
import querystring from "querystring"

const instance = axios.create({
    //网络请求公共配置
    timeout:5000,
    //withCredentials:true
})

const errorHandle = (status,info)=>{
    switch(status){
        case 400:
            console.log("语义有误");
        break;
        case 401:
            console.log("服务器认证失败");
            break;
        case 403:
            console.log("服务器拒绝访问");
            break;
        case 404:
            console.log("地址错误");
            break;
        case 500:
            console.log("服务器遇到意外");
            break;
        case 502:
            console.log("服务器无响应");
            break
        default:
            console.log(info);
            break;
    }
}
//拦截器最常用的

//发送数据之前
instance.interceptors.request.use(
    config=>{
        if(config.methods === "post"){
            config.data = querystring.stringify(config.data)
        }
        let token = localStorage.getItem("token");
        config.headers['token'] = token;
        //config:包含网络请求的所有信息
        return config
    },error=>{
        return Promise.reject(error)
    }
)

//获取数据之前
instance.interceptors.response.use(
    response =>{
        if(response.status === 200){
            if(response.data.code == 200){
                return Promise.resolve(response)
            }else{
                alert(response.data.msg)
            }
            
        }else{
            return Promise.reject(response);
        }
    },
    error=>{
        const { response } = error;
        //错误的处理才是我们需要最关注的
        errorHandle(response.status,response.info)
    }
)

export default instance;